/* Projects */
.container-fluid {
    width: 100%;
    text-align: left;
    padding: .5em .5em .5em .5em;
    color: #ffffff;
    background-color: #030125;
}
.projects {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 4em;
}

.projects > h3 {
    padding: 1em 0;
}

.col-30 {
    width: 30%;
    margin: auto;
}

/* Cards */
.card {
    background-color: #ffffff;
    width: 100%;
    margin: 1em auto; 
    border: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-img-top {
    height: 200px;
}
.card-title {
    font-size: 18px;
    font-weight: 600;
    padding: 1em;
    text-align: center;
}
.card-text {
    font-size: 16px;
    padding: 10px;
    overflow: auto;

}
.card-buttons {
    display: flex;
    align-items: flex-end;
    justify-content:center;
    flex-direction: row;
    padding: 1em;
}
.card-buttons .dropdown {
    margin: 0; 
}

.card > .list-group {
    width: 100%;
    height: 100%;

}

/* Yellow bubbles in cards */
#bubble-background {
    background-color: #B3C5D5;
    padding: 1em;
}
#bubbles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #B3C5D5;
}

.card > .list-group {
    width: 100%;
}
ul,
li,
.badge {
    font-size: 16px;
    padding: 0.3em;
    margin: 0.3em;
}
li,
.badge {
    min-height: 1em;
    position: relative;
}
.card-link {
    font-size: 16px;
    font-weight: bold;
}
.btn {
    font-family: 'Roboto Mono', monospace;
    text-align: center;
    margin-bottom: 1em;
    background-color: #000000;
    color: #ffffff;
}
.btn:hover {
    border: 1px solid #000000;
    background-color: #ffffff;
    color: #000000;
}

p {
    margin: 2em;
}



/* Project buttons */
#card-bottom {
    background-color: #a7bc5b;
    min-height: 3em;
}
#card-bottom,
a {
    text-align: center;
}
.project-concepts {
    color: #000000;
}
