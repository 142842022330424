/* Navbar styles */
.navbar {
  min-width: 100vw;
  height: 140px;
  background-color: #030125;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  overflow: hidden;
  z-index: 2; 
}

/* Name and icons */
.name > h2 {
  position: relative;
  font-family: 'Roboto Mono', monospace;
  color: #cee0e7;
  font-size: 150px; 
  font-weight: 600;
  margin-top: 20px;
  /*animation: colorChange 1s 0.3s steps(1) infinite;*/
  letter-spacing: 1px;
}

/* Name color change */
/* @keyframes colorChange {
  0% { color: #a7bc5b; }
  25% { color: #ffffff; }
  50%  { color: #e16162; }
  75% { color: #cee0e7; }
  100% { color: #ffffff; }

} */

.icon-container {
  display: flex;
  margin-right: 40px; 
  z-index: 3;
}

.icon {
  font-size: 48px;
  margin: 0 10px;
}

.icon #runner {
  animation: moveRunner 6.5s linear infinite 1s;
  position: absolute;
  left: -50px;
  top: 55%; 
  transform: translateY(-50%); 
}

.icon #ghost {
  animation: moveGhost 6.5s linear infinite 2s;
  position: absolute;
  left: -50px;
  top: 55%; 
  transform: translateY(-50%); 
}

@keyframes moveGhost {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw); 
  }
}

@keyframes moveRunner {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw); 
  }
}

/* Social links */
.nav-list {
  display: flex;
  list-style-type: none;
  margin-right: 20px; 
  padding: 0;
  position: relative;
  z-index: 2; 
  gap: .5em;

}

.nav-link {
  margin-top: 0; 
  margin-right: 1em; 
}

/*Github / linkedin icon styling */

#linkedin {
  color: #a7bc5b; 
  font-size: 35px;
  transition: color 0.3s; 
  
}

#linkedin:hover {
  color: #ffffff; 
}

#github {
  color: #a7bc5b; 
  transition: color 0.3s;
  font-size: 35px;

}

#github:hover {
  color: #ffffff; 
}

/* Tablet */
@media screen and (max-width: 1024px) {
  .navbar {
    height: 85px;
  }

  .nav-list {
    margin-top: 0; 
    padding: 0;
  }

  .name > h2 {
    font-size: 65px;
    margin-top: 35px;
  }

  .icon #runner,
  .icon #ghost {
    animation-duration: 5s;
    font-size: 40px;
    top: 50%;
  }

  #linkedin {
    font-size: 25px;
  }
  
  #github {
    font-size: 25px;
  
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .navbar {
    height: 55px;
  }

  .nav-list {
    width: 15%;
    gap: 0;
    margin: 5px;
  }

  .name > h2 {
    font-size: 26px; 
    width: 100%; 
  }

  .icon #runner,
  .icon #ghost {
    animation-duration: 3s; 
    font-size: 35px;
    top: 30%;
  }

  #linkedin #github{
    font-size: 20px;
  }

}