@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

main {
  font-family: 'DM Sans', sans-serif;
  font-size:18px;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

h3 {
  font-family: 'Roboto Mono', monospace;
  font-size: 3.5em;
  padding: 30px 0 40px;
  color: #ffffff;
  text-align: left;
  z-index: 0;
  margin-left: 1.5em;
}

@media (max-width: 1024px) {
  h3 {
    font-size: 2.5em;
    padding: 15px 0 20px;
    margin-left: 1em;
  }
}

/* iPhone size */
@media (max-width: 414px) {
  h3 {
    font-size: 2em;
    text-align: center;
    margin-left: -.4em;

  }
}




