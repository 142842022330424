.row {
    width: 100vw;
}

/* Knowledge and skills */
#skills-icon-group {
    padding: 2em;
    background-color: #2D0849;
}


/* Programming/tech stack icons */
#tools {
    position: relative;
    text-align: center;
    width: 70%;
    margin: 2em auto 0 11em;
    padding: 1em 0 3em;
    color: #ffffff;
}

#skill-icon,
.list-group-item,
li {
    position: relative;
    bottom: .3em;
    text-align: center;
    color: #a7bc5b;
    background-color: #2D0849;
    margin: 8px .1em 0;
    border: 0;
}

/* Education section */
#education {
   
    margin: 0;
    padding: 1em 0;
    color: #000000;
    text-align: left;
    width: 100vw;
    position: relative; 
    left: 50%;
    transform: translateX(-50%); 
    background-color: #ADC5CF;    
}

.education-details > h4 {
    color: #2D0849;
    margin-bottom: -1em;
}
/* Education school logos and text */
.col-20 > img {
    width: 100px;
    height: 100px;
}

.col-20,
.col-80 {
    display: flex;
    padding: 10px;
    text-align: center;
}

h5 {
    font-family: 'Roboto Mono', monospace;
    font-size: 22px;
    color: #000000;
}

.education-item {
    display: flex;
    align-items: center;
    margin: 3em;
}

.education-img {
    padding: 10px;
    text-align: center;
}

.education-details > .card-title,
.card-title {
    font-size: 32px;
    font-weight: bold;
    text-align: left;
    
}

.education-details > .card-text,
.card-text {
    font-size: 24px;
    margin-left: 1em;
}

/* Ensure the image is responsive */
.img-fluid {
    max-width: 100%;
    height: auto;
    max-height: 100px;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
    .education-item {
        flex-direction: row;
        text-align: center;
    }

    #tools {
        margin-left: 5em;
        padding-bottom: 2em;
    }

    #skills-header {
        margin: 0;
    }
    

    .education-details {
        width: 100%;
    }

    .education-details > .card-title,
    .card-title {
        font-size: 26px;
        text-align: left;
        
    }

    .education-details > .card-text,
    .card-text {
        font-size: 22px;
        text-align: left;
    }

}

@media screen and (max-width: 480px) {
    .education-item {
        flex-direction: column;
    }

    .list-group-item {
        font-size: 16px;
        left: .5em;
        padding-top: 5em;
    }

    #tools {
        margin-left: 3em;
        padding-bottom: 2em;
    }

    .education-details {
        padding: 0; 
    }

    .education-details > .card-title,
    .card-title {
        font-size: 26px;
        text-align: center;
    }

    .education-details > .card-text,
    .card-text {
        font-size: 20px;
        text-align: left;
    }
}